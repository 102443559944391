import { Step, StepLabel, Stepper, StepButton } from "@mui/material";
import { navigate } from "gatsby";
import { observer } from "mobx-react";
import * as React from "react"
import { useTranslation } from "react-i18next";
import useStores from "../hooks/use-stores"
import { CheckoutProcessStepper } from "../utilities/check-out";


const TravelPlanningStepper = observer(({ activeStep }: { activeStep: number }) => {
  const { t }: any = useTranslation();
  const TravelPlanningStepper = [
    { label: t('TALK_ABOUT_YOUR_PLAN'), url: '/cart' },
    { label: t('PLAN_YOUR_TRIP_DAYS'), url: '/cart/check-out' },
    { label: t('MAKE_BOOKING'), url: '' }
  ]

  return (
    <Stepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: 4 }}>
      {TravelPlanningStepper.map((step: any, i: number) => (
        <Step key={step.label} completed={i < activeStep}>
          {/* <StepButton
            disabled={true}
            onClick={() => navigate(step.url)}> */}
          <StepLabel className="text-sm sm:text-base font-light">{step.label}</StepLabel>
          {/* </StepButton> */}
        </Step>
      ))}
    </Stepper>
  )
});

export default TravelPlanningStepper;
