import React, { useCallback, useEffect, useRef, useState } from "react"
import Layout from "../../components/shared-layout";
import { Alert, Autocomplete, Avatar, Box, InputBase, ListItem, ListItemText, FormControlLabel, CircularProgress, IconButton, InputAdornment, Radio, RadioGroup, TextField, Typography, Checkbox, Grid, Chip, Tooltip, setRef, Switch, Popover } from "@mui/material";
import moment from "moment";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import SnowMoutain from "../images/snow-mountain.jpg";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import VmButton from "../../components/shared-button";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChatGPTPage from '../../components/travel-planning/chatgpt';
import PlaceSelectList from '../../components/travel-planning/place-select-list';
import { Link } from 'gatsby'
import { navigate } from "gatsby";
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import useStores from "../../hooks/use-stores";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TravelPlanningStepper from "../../components/travel-planning-stepper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HistoryIcon from '@mui/icons-material/History';
import RouteIcon from '@mui/icons-material/Route';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Wrapper } from "@googlemaps/react-wrapper";
import Map from "../../components/map-wrapper";
import Geocode from "react-geocode";
import { googleMapApiKey } from "../../constants/settings";
import { GoogleMap, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api';
import { DefaultRoutingObj } from "../../utilities/travel-planning";

const TripDaysPage = observer(() => {
  const { rootStore, bookingStore } = useStores();
  const { t }: any = useTranslation();
  const MAX_PLAN_DAYS_LIMIT = 10;
  const MAX_PLACES_PER_DAY = 8;
  const [step, setStep] = useState<"ChatGPT" | "Planning">("ChatGPT");
  const [generatedPlaces, setGeneratedPlaces] = useState<any>(
    {
      place: ['Sydney Harbour', 'Sydney Opera House', 'Sydney Tower', 'Sydney'],
      day: [
        ['Sydney Harbour', 'Sydney Opera House'],
        ['Sydney Tower'],
      ]
    }
  );
  const [savedPlaces, setSavedPlaces] = useState<any>();// Other day's places, update when day change
  const [isSamePlaceSelectable, setIsSamePlaceSelectable] = useState<any>(false);
  const [tripDayMode, setTripDayMode] = useState<"View" | "Edit">("Edit");
  const [currentTripDay, setCurrentTripDay] = useState<number>(0);// The day displaying
  const [revertConfirm, setRevertConfirm] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorDeleteEl, setAnchorDeleteEl] = useState<HTMLButtonElement | null>(null);
  const [openRoutePlanner, setOpenRoutePlanner] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<any>([]);
  const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: googleMapApiKey });

  // Route Planning
  const [directionResponse, setDirectionResponse] = useState<any>(null);
  const [distance, setDistance] = useState<any>(null);
  const [duration, setDuration] = useState<any>(null);

  useEffect(() => {
    // initialPlanning();
  }, [])

  useEffect(() => {
    console.log("TP_STEP_2", savedPlaces);
  }, [savedPlaces]);

  // 
  const initialPlanning = () => {
    if (generatedPlaces && generatedPlaces.place) {
      delete localStorage.TP_BOOKING;
      setCurrentTripDay(0);
      Geocode.setApiKey(googleMapApiKey);
      let req: any = JSON.parse(JSON.stringify(generatedPlaces));
      console.log("TP_STEP_1", req);
      onDefineLocationCoordinates(req);
      setStep("Planning");
    }
  }
  const onDefineLocationCoordinates = (placeObj: any) => {
    placeObj.coords = [];
    placeObj.day.map((day: any) => {
      let dayCoordObj: any = [];
      day.map((p: string) => {
        Geocode.fromAddress(p).then((response: any) => {
          let latitude: number | null = null;
          let longtitude: number | null = null;
          if (response.results.length > 0) {
            const { lat, lng } = response.results[0].geometry.location;
            latitude = lat;
            longtitude = lng;
          };
          dayCoordObj.push({ ...DefaultRoutingObj, name: p, latitude, longtitude });
        })
      })
      placeObj.coords.push(dayCoordObj);
      setSavedPlaces(placeObj);
      setRefresh([]);
    })
  }
  const isTripPlanningReady = () => {
    return generatedPlaces && generatedPlaces.place && savedPlaces && savedPlaces.place && savedPlaces.day && savedPlaces.coords;
  }
  const editPlaceInCurrentDay = (place: string, action: "Add" | "Remove") => {
    if (isTripPlanningReady()) {
      console.log(savedPlaces);
      let tempSavedPlaces = savedPlaces;
      let targetPlaceObj = savedPlaces.day[currentTripDay].find((gPlace: string) => gPlace === place);
      switch (action) {
        case "Add":
          if (!targetPlaceObj) {
            tempSavedPlaces.day[currentTripDay].push(place);
            let req: any = { ...DefaultRoutingObj, name: place };
            Geocode.fromAddress(place).then((response: any) => {
              let latitude: number | null = null;
              let longtitude: number | null = null;
              if (response.results.length > 0) {
                const { lat, lng } = response.results[0].geometry.location;
                latitude = lat;
                longtitude = lng;
              };
              req.latitude = latitude;
              req.longtitude = longtitude;
              tempSavedPlaces.coords[currentTripDay].push(req);
              setSavedPlaces(tempSavedPlaces);
            })
          }
          break;
        case "Remove":
          if (targetPlaceObj) {
            let idx = tempSavedPlaces.day[currentTripDay].indexOf(targetPlaceObj);
            tempSavedPlaces.day[currentTripDay].splice(idx, 1);
            tempSavedPlaces.coords[currentTripDay].splice(idx, 1);
          }
          setSavedPlaces(tempSavedPlaces);
          break;
      }
    }
    setRefresh([]);
  }
  const onChangeSamePlaceSelectable = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSamePlaceSelectable(event.target.checked);
  }
  const onChangeTripDay = (direction?: "prev" | "next") => {
    switch (direction) {
      case "next":
        if (currentTripDay < savedPlaces.day.length) setCurrentTripDay(currentTripDay + 1);
        break;
      case "prev":
        if (currentTripDay > 0) setCurrentTripDay(currentTripDay - 1);
        break;
    }
  }
  const editDayTrip = (action: "Add" | "Delete") => {
    switch (action) {
      case "Add":
        if (currentTripDay + 2 > MAX_PLAN_DAYS_LIMIT) rootStore.notify(t(''), 'warning');
        else {
          savedPlaces.day.push([]);
          setCurrentTripDay(savedPlaces.day.length - 1);
          rootStore.notify(t('NEW_TRIP_DAY_ADDED'), 'success');
        }
        break;
      case "Delete":
        if (savedPlaces.day.length > 1) {
          setCurrentTripDay(currentTripDay - 1);
          savedPlaces.day.splice(currentTripDay, 1);
          rootStore.notify(t('TRIP_DAY_DELETE'), 'success');
        }
        break;
    }
    setRefresh([]);
  }
  const isPlaceDisabled = (placeName: string) => {
    let placeIsSelectedInSomeDay = false;
    if (!isSamePlaceSelectable) {
      for (var day of savedPlaces.day) {
        for (var p of day) {
          if (p === placeName) {
            placeIsSelectedInSomeDay = true;
            break;
          }
        }
      }
    }
    return placeIsSelectedInSomeDay || savedPlaces.day[currentTripDay].find((pName: string) => pName === placeName) ||
      savedPlaces.day[currentTripDay].length > MAX_PLACES_PER_DAY;
  }
  const onCloseRevertConfirm = () => {
    setRevertConfirm(false);
    setAnchorEl(null);
  }
  const onCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
    setAnchorDeleteEl(null);
  }
  const constructPlacesUrl = () => {
    let url: string = "";
    if (savedPlaces.day[currentTripDay].length > 0) {
      savedPlaces.day[currentTripDay].map((p: string) => url += `${p}--`)
    }
    return url
  }
  const onCaculateRouting = () => {
    if (isTripPlanningReady() && savedPlaces.coords[currentTripDay].length == savedPlaces.day[currentTripDay].length) {
      if (savedPlaces.day[currentTripDay].length > 1) {
        bookingStore.runRoutePlanner(savedPlaces.coords[currentTripDay])
          .then((planner: any) => {
            let wayPoints: any = [];
            for (var key in planner) {
              wayPoints.push({ location: { lat: +planner[key][1], lng: +planner[key][2] } })
            }
            if (wayPoints.length > 0) {
              const origin = wayPoints.shift().location;
              const destination = wayPoints.pop().location;
              const directionsService = new google.maps.DirectionsService()
              directionsService.route({
                origin: origin,
                destination: destination,
                waypoints: wayPoints,
                travelMode: google.maps.TravelMode.DRIVING,
              }).then((results) => setDirectionResponse(results));
            }
          })
      } else rootStore.notify(t('YOU_NEED_ADD_AT_LEAST_TWO_LOCATIONS_TO_VIEW_THE_ROUTE_FOR_CURRENT_TRIP_DAY'), 'warning');
    }
  }

  return (
    <Layout pageName={t('TRAVEL_PLANNING')}>
      <Box className={PADDING_HORIZONTAL}>
        {/* START STEP 1 - CHATGPT */}
        {step === "ChatGPT" && <>
          <TravelPlanningStepper activeStep={0} />
          <ChatGPTPage placeNames={generatedPlaces} setGeneratedPlaces={setGeneratedPlaces} />
          <VmButton
            onClick={initialPlanning}
            disabled={!generatedPlaces || !generatedPlaces.place || generatedPlaces.place.length == 0}
          >
            {t('PLAN_THIS_TRIP')}
          </VmButton>
        </>}
        {/* END STEP 1 - CHATGPT  */}
        {/* START STEP 2 - PLANNING */}
        {(step === "Planning" && isTripPlanningReady()) && <>
          <TravelPlanningStepper activeStep={1} />
          <Box className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {/* Start Left Section */}
            <Box className="bg-white shadow-lg border p-4 border-t-8 border-t-themeYellow">
              <p className="font-bold 2xl:text-lg">{t('STEP_1_ADD_RECOMMENED_PLACES_TO_YOUR_TRIP')}</p>
              <Box className="grid grid-cols-3 2xl:grid-cols-4 gap-2 mt-4">
                {generatedPlaces && generatedPlaces.place.map((placeName: string, i: number) => (
                  <Chip
                    color={isPlaceDisabled(placeName) ? "default" : "warning"}
                    disabled={isPlaceDisabled(placeName)}
                    key={`location_${i}`}
                    label={placeName}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    // onClick={() => editPlaceInCurrentDay(placeName, "Add")}
                    onDelete={() => editPlaceInCurrentDay(placeName, "Add")}
                    deleteIcon={<AddCircleIcon />}
                  />
                ))}
              </Box>
              <Box sx={ITEM_PERFECT_INLINED} className="justify-end mt-6">
                <FormControlLabel label={t('SAME_PLACES_ALLOWED')}
                  control={<Switch
                    checked={isSamePlaceSelectable}
                    onChange={onChangeSamePlaceSelectable}
                    color="primary"
                  />} />
              </Box>
            </Box>
            {/* End Left Section */}
            {/* Start Right Section */}
            <Box className="bg-white shadow-lg border border-t-8 border-t-themeYellow">
              <Box className="p-4 pt-2">
                <Box sx={ITEM_PERFECT_INLINED} className="justify-between">
                  <p className="font-bold 2xl:text-lg">{t('STEP_2_EDIT_YOUR_TRIP')}</p>
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <Tooltip title={t('ADD_A_NEW_TRIP_DAY')}>
                      <IconButton disabled={currentTripDay + 1 == MAX_PLAN_DAYS_LIMIT} onClick={() => editDayTrip("Add")}><AddCircleOutlineIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title={t('DELETE_CURRENT_DAY')}>
                      <IconButton
                        disabled={currentTripDay == 0}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          setAnchorDeleteEl(event.currentTarget);
                          setDeleteConfirm(true)
                        }}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('REVERT_TRIP')}>
                      <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(event.currentTarget);
                        setRevertConfirm(true)
                      }}>
                        <HistoryIcon />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      open={deleteConfirm}
                      anchorEl={anchorDeleteEl}
                      onClose={onCloseDeleteConfirm}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      <p className="p-4">{t('ARE_YOU_SURE_IN_DELETING_THIS_TRIP_DAY')}?</p>
                      <Box sx={{ padding: 2, paddingTop: 0, textAlign: "right" }}>
                        <VmButton onClick={() => {
                          setCurrentTripDay(0);
                          editDayTrip("Delete")
                          onCloseDeleteConfirm();
                        }}>
                          {t('DELETE_NOW')}
                        </VmButton>
                      </Box>
                    </Popover>
                    <Popover
                      open={revertConfirm}
                      anchorEl={anchorEl}
                      onClose={onCloseRevertConfirm}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      <p className="p-4">{t('ARE_YOUR_SURE_TO_REVERT_YOUR_PLANNING_TO_DEFAULT')}?</p>
                      <Box sx={{ padding: 2, paddingTop: 0, textAlign: "right" }}>
                        <VmButton onClick={() => {
                          setCurrentTripDay(0);
                          initialPlanning();
                          setRefresh([]);
                          onCloseRevertConfirm();
                          rootStore.notify(t('REVERT_SUCCESS'), 'success');
                        }}>
                          {t('REVERT_NOW')}
                        </VmButton>
                      </Box>
                    </Popover>
                  </Box>
                </Box>
                <Box className="mb-4" sx={ITEM_PERFECT_INLINED}>
                  <p className="mr-2">{t('DAY_A')}{currentTripDay + 1}{t('DAY_B')} ({savedPlaces.day[currentTripDay].length})</p>
                  {!generatedPlaces.day[currentTripDay] && <Chip label={t('NEW_ADDED_TRIP_DAY')} color="error" size="small" />}
                </Box>
                <Box className="grid grid-cols-3 2xl:grid-cols-4 gap-2 mb-4">
                  {savedPlaces.day[currentTripDay].map((placeName: string, i: number) => (
                    <Chip
                      color={"warning"}
                      disabled={tripDayMode === "View"}
                      key={`day_location_${i}`}
                      label={placeName}
                      variant="outlined"
                      sx={{ width: '100%' }}
                      // onClick={() => editPlaceInCurrentDay(placeName, "Remove")}
                      onDelete={() => editPlaceInCurrentDay(placeName, "Remove")}
                    />
                  ))}
                </Box>
                <Box className='text-center mt-8'>{t('SELECTED_PLACES')}: {savedPlaces.day[currentTripDay].length}/{MAX_PLACES_PER_DAY}</Box>
              </Box>

              <Box sx={ITEM_PERFECT_INLINED} className="justify-between border-t">
                <VmButton bg="bg-themeYellow" borderColor="border-themeYellow" className="p-1" disabled={currentTripDay == 0} onClick={() => onChangeTripDay("prev")}>
                  <KeyboardArrowLeftIcon fontSize="large" color="inherit" />
                </VmButton>
                <Box className="mx-4">{t('DAYS')}: {currentTripDay + 1} / {savedPlaces.day.length}</Box>
                <VmButton bg="bg-themeYellow" borderColor="border-themeYellow" className="p-1" disabled={currentTripDay == savedPlaces.day.length - 1} onClick={() => onChangeTripDay("next")}>
                  <KeyboardArrowRightIcon fontSize="large" color="inherit" />
                </VmButton>
              </Box>
              <Box sx={ITEM_PERFECT_INLINED} className="shadow-lg">
                <Box className="w-1/2">
                  <VmButton borderColor="border-sky-500" bg="bg-sky-500" fitContent={false} onClick={onCaculateRouting}>
                    <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
                      <RouteIcon />
                      <p className="ml-2">{t('TRIP_ROUTE')}</p>
                    </Box>
                  </VmButton>
                </Box>
                <Box className="w-1/2">
                  <VmButton borderColor="border-green-500" bg="bg-green-500" fitContent={false} onClick={() => { }}>
                    <a href={`/travel-planning/booking-list?places=${constructPlacesUrl()}`} target="_blank">
                      <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
                        <AddShoppingCartIcon />
                        <p className="ml-2">{t('WANT_TO_BOOK')}</p>
                      </Box>
                    </a>
                  </VmButton>
                </Box>
              </Box>
            </Box>
            {/* End Right Section */}
          </Box>

          {/* Map Area */}
          {!openRoutePlanner && <Box className="mt-6">
            <p className="mb-2 font-bold 2xl:text-lg">{t('ROUTE_PLANNER')}: {t('DAY_A')}{currentTripDay + 1}{t('DAY_B')}</p>
            {isLoaded && <GoogleMap
              mapContainerStyle={{ height: "100%", minHeight: 400 }}
              center={{ lat: -31.363, lng: 151.044 }}
              zoom={6}
            >
              {directionResponse && <DirectionsRenderer directions={directionResponse} />}
            </GoogleMap>}
          </Box>}
        </>}
        {/* End Map Area */}
        {/* END STEP 2 - PLANNING */}
      </Box>
    </Layout >
  )
});

export default TripDaysPage;
