import React, { useEffect, useState } from "react"
import Layout from "../../components/shared-layout";
import { Alert, Autocomplete, Avatar, Box, InputBase, ListItem, ListItemText, FormControlLabel, CircularProgress, IconButton, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import moment from "moment";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import VmButton from "../../components/shared-button";
import VmStepperTitle from "../../components/shared-stepper-title";
// @ts-ignore
import EnjoysAus from "../../images/enjoyaus.jpg";
import SendIcon from '@mui/icons-material/Send';

const AUDateTimeFormat = "DD/MM/YYYY hh:mm A";

const GPT_MODEL = "gpt-3.5-turbo";//use gpt-4 in the future

const API_KEY = "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";// Steve's free key

const warpTag = (content: string, keywordList: any, tagName: string) => {
  if (content === "" || keywordList == null || keywordList == undefined || keywordList.length == 0) {
    return content
  }
  keywordList.place.sort(function (a: string, b: string) {
    return a.length - b.length;
  }).map((keyword: string) => {

    const a = content.toLowerCase();
    const b = keyword.toLowerCase();

    const indexof = a.indexOf(b);
    const c = indexof > -1 ? content.substr(indexof, keyword.length) : '';
    const val = `<${tagName} style="text-decoration: underline; font-weight:bold; font-style: italic;">${c}</${tagName}>`;

    const regS = new RegExp(keyword, 'gi');
    content = content.replace(regS, val);
  })
  return `<div>` + content + `<\div>`
}


const ChatGPTPage = observer((props: any) => {
  const { placeNames, setPlaceNames } = props
  const { t }: any = useTranslation();
  const [msgContent, setMsgContent] = useState<string>("");

  const [messages, setMessages] = useState<any>([
    {
      message: "Hello, I'm ChatGPT! Ask me anything!\n For example, I want to go to Melbourne for 4 days, I want to taste the food, go to enjoy the natural attractions and am interested in history, help me plan. Would love to stay in a 5 star hotel.",
      sentTime: "just now",
      sender: "ChatGPT"
    }
  ]);
  const [botMessageCount, setBotMessageCount] = useState<number>(1);
  const [lastMessage, setLastMessage] = useState<string>('');
  const [showRetry, setShowRetry] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [messageBuffer, setMessageBuffer] = useState<any>(null);
  const systemMessage = { //  Explain things like you're talking to a software professional with 5 years of experience.
    "role": "system", "content": "You are an Australian based travel agency providing travel advice to visitors from all over the world. Please make sure that the places you are talking about are really there."
  }
  const systemMessagePlaceName = [
    { "role": "system", "content": "You are a text processing program and just need to return the results." },
    { "role": "user", "content": lastMessage},
  ]
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (botMessageCount > 1) {
      getPlaceNames();
    }
    // console.log('message effect', botMessageCount);
  }, [botMessageCount])

  const handleRetry = () => {
    setShowRetry(false)
    if (step == 0) {
      handleSend(messageBuffer);
    } else {
      getPlaceNames();
    }
  }

  const handleSend = async (message: any) => {
    setMsgContent('')
    setMessageBuffer(message)
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user"
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };


  async function processMessageToChatGPT(chatMessages: any) { // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}

    let apiMessages = chatMessages.map((messageObject: any) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message }
    });


    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act. 
    const apiRequestBody = {
      "model": GPT_MODEL,
      "temperature": 1,
      "messages": [
        systemMessage,  // The system message DEFINES the logic of our chatGPT
        ...apiMessages // The messages from our chat with ChatGPT
      ]
    }
    try {
      await fetch("https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(apiRequestBody)
        }).then((data) => {
          return data.json();
        }).then((data) => {
          setBotMessageCount(botMessageCount + 1)
          setLastMessage(data.choices[0].message.content)
          setStep(1)
        });
    } catch {
      setShowRetry(true)
      setIsTyping(false)
    }
  }
  async function getPlaceNames() { // get place names in JSON format from the last answer.

    const apiRequestBodyForJson = {
      "model": GPT_MODEL,
      "temperature": 0,
      "messages": systemMessagePlaceName,
    }
    try {
      await fetch("https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(apiRequestBodyForJson)
        }).then((data) => {
          return data.json();
        }).then((data) => {
          let names = null
          try {
            names = JSON.parse(data.choices[0].message.content);
            if (names.day != undefined) {
              let temp: any = []
              names.day.map((oneDay: any) => {
                if (oneDay == null) {
                  temp.push([])
                } else {
                  temp.push(oneDay)
                }
              })
              names.day = temp
            }
          } catch {
            names = null
          } finally {
            setPlaceNames(names)
            setMessages([...messages, {
              message: lastMessage,
              sender: "ChatGPT"
            }]);
            setIsTyping(false);
            setStep(0);
          }
          // console.log('JSON', data.choices[0].message.content);
        })
    } catch {
      setShowRetry(true)
      setIsTyping(false)
    }
  }

  return (
    <>
      <Box className="col-span-2 bg-white border border-gray-200 shadow-md p-4 mb-4">
        <Box className="border-2 border-themeGreen" sx={{ p: 2, display: 'flex', alignItems: 'center', width: '100%' }} >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            value={msgContent}
            placeholder={t('MSG_LIMIT_500_CHARS')}
            inputProps={{ 'aria-label': 'Search...', maxLength: 500 }}
            rows={4}
            multiline
            onChange={(event: any) => setMsgContent(event.target.value)}
          />
          <Box className="pt-14">
            <VmButton onClick={() => handleSend(msgContent)} className="px-3 pr-2"><SendIcon /></VmButton>
            <p className="text-sm text-gray-400 text-center">{msgContent.length}/500</p>
          </Box>
        </Box>
        {<>
          {messages.length > 0 &&
            <Box className="">
              {messages.map((msg: any, i: number) => (
                <>
                  <ListItem key={`msg_${i}`} className="text-4xl" disablePadding sx={{ marginTop: i == 0 ? 0 : -2 }}>
                    {msg.sender == 'ChatGPT' && <Avatar alt="Remy Sharp" src={EnjoysAus} />}
                    <ListItemText className="text-left" sx={{ marginTop: 4 }}>
                      <p style={{ width: 'fit-content' }} className={`${msg.sender == 'user' ? 'float-right mr-4' : 'ml-4'} whitespace-pre-wrap bg-blue-200 text-black p-3 rounded-xl ${msg.sender == 'ChatGPT' ? 'rounded-tl-none' : 'rounded-tr-none'}`} dangerouslySetInnerHTML={{ __html: warpTag(msg.message, placeNames, 'span') }}></p>
                    </ListItemText>
                    {msg.sender == 'user' && <Avatar alt="Remy Sharp" src={EnjoysAus} />}
                  </ListItem>
                  {/* <p className={`${msg.admin ? 'ml-14' : 'text-right mr-14'} text-gray-400 text-sm`}>{moment(msg.createTime).format(AUDateTimeFormat)}</p> */}
                  {/* Only show the date/time after the last message sent by the user */}
                  {/* {(i + 1 == messageStore.msgDetail.length || messageStore.msgDetail[i + 1].admin != msg.admin) &&
                          <p className={`${msg.admin ? 'ml-14' : 'text-right mr-14'} text-gray-400 text-sm`}>{moment(msg.createTime).format(AUDateTimeFormat)}</p>} */}
                </>
              ))}
              {
                isTyping ? <ListItem className="text-4xl" disablePadding sx={{ marginTop: -2 }}>
                  <Avatar alt="Remy Sharp" src={EnjoysAus} />
                  <ListItemText className="text-left" sx={{ marginTop: 4 }}>
                    <p style={{ width: 'fit-content' }} className={`ml-4 bg-blue-200 text-black p-3 rounded-xl rounded-tl-none `} >ChatGPT is typing ...</p>
                  </ListItemText>
                </ListItem> : <></>
              }
              {
                showRetry ? <button onClick={handleRetry}>retry</button> : <></>
              }
            </Box>}
        </>}
      </Box>
    </>
  );
})

export default ChatGPTPage;
